import { useContext } from 'react'
import './contact.css'
import Phone from '../../../images/call-outcoming.svg'
import Addres from '../../../images/pin.svg'
import Email from '../../../images/mail.svg'
import { ThemeContext } from '../../../context'

const Contact = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode

  return (
    <div className='c' id='contact'>
      <div className='c-bg'></div>
      <div className='c-wrapper'>
        <div className='c-left'>
          <h1 className='c-title'>Lépjen velünk kapcsolatba!</h1>
          <div className='c-info'>
            <div className='c-info-item'>
              <img src={Phone} alt='Phone icon' className='c-icon' />
              +36(30)326-7891
            </div>
            <div className='c-info-item'>
              <img src={Email} alt='Email icon' className='c-icon' />
              baufenster.kft@gmail.com
            </div>
            <div className='c-info-item'>
              <img src={Addres} alt='Addres icon' className='c-icon' />
              2660 Balassagyarmat, Rákóczi út 65/C
            </div>
          </div>
        </div>
        <div className='c-right'>
          <p className='c-desc'>
            <b>Kérdése van?</b>
            <br />
            Levelére a lehető leghamarabb, 24 órán belül válaszolunk, vagy
            megkeressük a megadott elérhetőségek (pl. telefon) egyikén!
            Észrevétele, kérdése fontos nekünk!
          </p>
          <form
            name='contact'
            method='post'
            data-netlify='true'
            onSubmit='submit'
            netlify-honeypot='bot-field'
            action='/success'>
            <input type='hidden' name='form-name' value='contact' />
            <input
              style={{
                backgroundColor: darkMode && '#333',
                color: darkMode && '#fff',
              }}
              type='text'
              placeholder='Név'
              name='name'
              required
            />
            <input
              style={{
                backgroundColor: darkMode && '#333',
                color: darkMode && '#fff',
              }}
              type='text'
              placeholder='Tárgy'
              name='subject'
            />
            <input
              style={{
                backgroundColor: darkMode && '#333',
                color: darkMode && '#fff',
              }}
              type='email'
              placeholder='Email'
              name='email'
              required
            />
            <input
              style={{
                backgroundColor: darkMode && '#333',
                color: darkMode && '#fff',
              }}
              type='tel'
              placeholder='Telefonszám'
              name='phone'
              required
            />
            <textarea
              style={{
                backgroundColor: darkMode && '#333',
                color: darkMode && '#fff',
              }}
              name='message'
              rows='5'
              placeholder='Üzenet'
              required></textarea>
            <div className='check'>
              <input id='chkbox' type='checkbox' className='chbox' required />
              <label htmlFor='chkbox' className='gdpr-warning'>
                Tudomásul vettem és elfogadom az adatkezelési tájékoztatóban
                foglaltakat
              </label>
            </div>
            <button type='submit'>Küldés</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
