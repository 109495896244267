import './map.css'

const Map = () => {
  return (
    <div>
      <div className='mapouter'>
        <div className='gmap_canvas'>
          <iframe
            title='Fenster-Bau'
            scrolling='no'
            marginHeight='0'
            marginWidth='0'
            src='https://maps.google.com/maps?q=2660%20Balassagyarmat,%20R%C3%A1k%C3%B3czi%20u.%2065/C&t=&z=13&ie=UTF8&iwloc=&output=embed'
            width='100%'
            height='100%'
            frameBorder='0'></iframe>
        </div>
      </div>
    </div>
  )
}

export default Map
