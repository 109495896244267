import './about.css'
import fensterbau1 from '../../../images/fensterbau-szombathely-2.gif'
import fensterbau2 from '../../../images/fensterbau-commercial-szombathely.png'
import fensterbau3 from '../../../images/commercial-3.png'

const About = () => {
  return (
    <div className='wrapper'>
      <div className='a' id='about'>
        <div className='a-left'>
          <div className='a-card'>
            <img
              className='a-img'
              src={fensterbau1}
              alt='Fenster-Bau reklám'
              loading='lazy'
            />
          </div>
        </div>
        <div className='a-right'>
          <h1 className='a-title'>Rólunk</h1>
          <p className='a-sub'>
            Cégünk, a Fenster-Bau Kft. elsősorban nyílászárók értékesítésével és
            kivitelezésével foglalkozik. A cég vezetése és szakemberei több,
            mint tíz éves - belföldi és külföldi - szakmai tapasztalattal
            rendelkeznek építési kivitelezés területén.
          </p>
          <div className='a-desc'>
            <p>
              Nagy gondossággal, kézséggel, magas színvonalon, a legmodernebb
              technológiával, korszerű anyagokkal valósítunk meg kivtelezéseket,
              felújításokat (műanyag, fa és alumínium nyílászárók cseréje,
              árnyékolástechnika).
            </p>
            <p>
              Üzletpolitikánknak, minőségi munkavégzésünknek, szakképzett
              dolgozóinknak köszönhetően az ügyfeleink, vásárlóink elvárásainak
              maximális figyelembe vételével, igényeik teljes körű kielégítésére
              törekszünk, a lehető legkedvezőbb áron.
            </p>
            <p>
              Tevékenységünk természetesen nem korlátozódik <span>Nógrád </span>
              megyére. <span>Pest</span>, <span>Heves</span> valamint most már
              <span> Vas</span> megye területén is rendszeresen végzünk
              nyílászáró beépítéseket, cseréket illetve egyéb kivitelezéseket,
              ügyfeleink legnagyobb megelégedésére.
            </p>
          </div>
        </div>
      </div>
      <div className='images-wrapper'>
        <div className='side-container'>
          <div className='img-card'>
            <img
              className='a-img'
              src={fensterbau2}
              alt='Fenster-Bau reklám'
              loading='lazy'
            />
          </div>
        </div>
        <div className='side-container'>
          <div className='img-card'>
            <img
              className='a-img'
              src={fensterbau3}
              alt='Fenster-Bau reklám'
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div className='about-bottom'>
        <h3>
          Jelenleg Nógrád megyében, Balassagyarmaton található üzlethelyiségünk
          illetve bemutatótermünk. Azonban természetesen településtől
          függetlenül elégítjük ki ügyfeleink igényeit.
        </h3>
      </div>
      <div className='cta-call'>
        <a className='btn' href='tel:06303267891'>
          Kérdése van? Hívjon!
        </a>
      </div>
    </div>
  )
}

export default About
