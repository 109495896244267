export const products = [
  {
    id: 1,
    title: 'Műanyag, alumínium, fa ablakok-ajtók',
    description:
      'Műanyag, alumínium, fa ablakok és ajtók forgalmazásával, beszerelésével szerzett évtizedes tapasztalatunknak hála a legjobb ár-érték arányú, legjobb minőségű nyílászárókat tudjuk Önnek ajánlani. Legyen szó szinte bármilyen méretről, színről, stílusról, megtaláljuk az Önnek legmegfelelőbb választást. Modern megjelenés, vagy valami "klasszikus"? Ön dönt, de a minőséget és a tartósságot garantáljuk.',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634735048/FENSTERBAU/08781B51-2FFC-4F1B-ABCB-5C3FA7DEF663.png',
    alt: 'Bejárati ajtó',
  },
  {
    id: 2,
    title: 'Redőnyök (kézi és motoros működtetésűek)',
    description:
      'A külsőtokos műanyag redőny a legkeresettebb típus, nem csak a kedvező ára miatt, de azért is, mert bármilyen típusú nyílászáró külső síkjára felszerelhető, felújításhoz, vagy új építés esetén is alkalmazható. A korszerű redőnylécek kedvező tekeredési méretekkel rendelkeznek, ezért viszonylag kis tokmérettel gyárthatók. Innovatív megoldások is találhatók a redőnyben: lefutójának belsejében elhelyezett kefével a halkabb működést, a csapágyazott tengelyszerkezet a könnyebb kezelést, míg az alumínium tokvég és záróléc hosszabb élettartamot biztosít a redőnyöknek.',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634736228/FENSTERBAU/redex-kulso-tokos-muanyag-redony-2.jpg',
    alt: 'Redőny',
  },
  {
    id: 3,
    title: 'Zsaluziák - kézi és motoros kivitelben',
    description:
      'A zsaluzia egy magas presztízsű árnyékoló, látványban és funkcióban utolérhetetlen tulajdonságai miatt. A lamellákat (redőnyléceket) leengedve a fény beesési szögén változtatva finoman állítható a bejutó fény mennyisége. Megvalósítható a fokozott fényzárás, az íves formájú lamelláknak köszönhetően. Ezek a lamellák alumínium ötvözetből készülnek, melynek járulékos előnyei közé tartozik a betörésbiztonság, és a jó szélállóság is. A lamellák mozgatásnak legelegánsabb formája a motoros kivitel, amit távirányítóval hozhatunk működésbe.',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634735718/FENSTERBAU/zsaluzia-homlokzati.png',
    alt: 'Zsaluzia',
  },
  {
    id: 4,
    title: 'Beltéri ajtók, átfogó tokos és blokk tokos kivitelben',
    description:
      'A beltéri ajtók legnagyobb választékát tudjuk nyújtani ügyfeleinknek a legváltozatosabb méretben és kivitelben, a legkülönbözőbb színekben, blokktokos és átfogó tokos kivitelben. Cégünk az Ön igényeinek megfelelő, kiváló minőségű ajtókat közvetlenül a gyártótól rendeli meg, melyek beépítését szakképzett kollégáiánk végzik el.',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634736357/FENSTERBAU/bertolotto-sydney-materik.jpg',
    alt: 'bertolotto-sydney beltéri ajtó',
  },
  {
    id: 5,
    description:
      'A reluxa belső árnyékoló berendezés épületekben. Ma már leggyakrabban alumíniumból készül, de léteznek reluxák műanyagból és fából is. A reluxa elforgatható lamellákból áll, amelyekkel szabályozható a bejövő fény mennyisége és iránya. A lamellák 50, 25 vagy 16 mm-esek, különböző színben. A reluxa téglalap alakú ablakoknál felhúzható, míg trapéz, háromszög és boltíves nyílások esetében csak elforgatása lehetséges. A lamellák forgatása forgatópálcával történik, zsinórral felhúzhatók. Leggyakrabban az ablakok belső részére szerelik.',
    title: 'Reluxák, Roletták, Szalagfüggönyök',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634737276/FENSTERBAU/ruan-richard-LLIrneg01Ks-unsplash.jpg',
    alt: 'Reluxa',
  },
  {
    id: 6,
    title: 'Szúnyoghálók (műanyag, alumínium)',
    description:
      'A ma használatos rovarhálóknak a mezőgazdaságban, az állattenyésztésben és a mindennapi életben is nagy jelentősége van. Ezeknek a hálóknak a fő feladata, hogy a kórokozókat, fertőzéseket terjesztő, kellemetlen viselkedésű rovarokat távol tartsák az emberek életterétől. Cégünk több színben, kivitelben, méretben, műanyag vagy alu kerettel forgalmaz illetve szerel szúnyoghálókat.',
    img: 'https://res.cloudinary.com/maja0426/image/upload/v1634737705/FENSTERBAU/kenrick-mills-Y2HdSe0S8FU-unsplash.jpg',
    alt: 'Szúnyogháló',
  },
]
