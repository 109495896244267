import { React, useContext } from 'react'
import About from '../../mainPage/about/About'
import Contact from '../../mainPage/contact/Contact'
import Footer from '../../UI/footer/Footer'
import Intro from '../../mainPage/intro/Intro'
import Nav from '../../UI/nav/Nav'
import ProductList from '../../mainPage/productList/ProductList'
import { ThemeContext } from '../../../context'
// import Action from '../../mainPage/action/Action'
import Map from '../../mainPage/map/Map'
import Gallery from '../../mainPage/gallery/Gallery'

function Home() {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  return (
    <div
      style={{
        backgroundColor: darkMode ? '#222' : '#fff',
        color: darkMode ? '#fff' : '#222',
      }}>
      <Nav />
      <Intro />
      <About />
      <ProductList />
      {/* <Action /> */}
      <Gallery />
      <Contact />
      <Map />
      <Footer />
    </div>
  )
}

export default Home
