import { Carousel } from 'react-carousel-minimal'
import './gallery.css'
import image1 from '../../../images/szakuzlet-1.webp'
import image5 from '../../../images/szakuzlet-5.webp'
import truck1 from '../../../images/truck1.webp'
import truck2 from '../../../images/truck2.webp'
import window1 from '../../../images/window1.webp'

function Gallery() {
  const data = [
    {
      image: `${image1}`,
    },
    {
      image: `${image5}`,
    },
    {
      image: `${truck1}`,
    },
    {
      image: `${truck2}`,
    },
    {
      image: `${window1}`,
    },
  ]

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className='gallery' id='gallery'>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <h2>Képgaléria</h2>
        {/* <p>
          Néhány a munkáink közül, melyekre büszkék vagyunk, és
          megelégedettséggel töltötte el ügyfeleinket.
        </p> */}
        <div
          style={{
            padding: '0 20px',
          }}>
          <Carousel
            data={data}
            time={5000}
            width='1200px'
            height='700px'
            captionStyle={captionStyle}
            // radius='10px'
            // slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition='bottom'
            automatic={true}
            dots={true}
            pauseIconColor='white'
            pauseIconSize='40px'
            slideBackgroundColor='darkgrey'
            slideImageFit='cover'
            thumbnails={false}
            thumbnailWidth='100px'
            style={{
              textAlign: 'center',
              maxWidth: '1200px',
              maxHeight: '700px',
              margin: '40px auto',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Gallery
