import { React } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ScrollToTop } from 'react-router-scroll-to-top'
import Main from './components/pages/main/Main'
import Cookies from './components/pages/cookies/Cookies'
import Impressum from './components/pages/impressum/Impressum'
import Gdpr from './components/pages/gdpr/Gdpr'
// import FloatingBtn from './components/UI/floatingBtn/FloatingBtn'

function App() {
  return (
    <BrowserRouter>
      {/* <FloatingBtn /> */}
      <ScrollToTop />
      <Switch>
        <Route path='/' component={Main} exact />
        <Route path='/cookies' component={Cookies} />
        <Route path='/impresszum' component={Impressum} />
        <Route path='/gdpr' component={Gdpr} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
