import './toggle.css'
import Sun from '../../../images/brightness-high.svg'
import Moon from '../../../images/moon.svg'
import { useContext } from 'react'
import { ThemeContext } from '../../../context'

const Toggle = () => {
  const theme = useContext(ThemeContext)

  const handleClick = () => {
    theme.dispatch({ type: 'TOGGLE' })
  }
  return (
    <div className='t'>
      <img src={Sun} alt='nap ikon' className='t-icon' />
      <img src={Moon} alt='hold ikon' className='t-icon' />
      <div
        className='t-button'
        onClick={handleClick}
        style={{
          left: theme.state.darkMode ? '2px' : '26px',
          backgroundColor: theme.state.darkMode ? 'yellow' : '',
        }}></div>
    </div>
  )
}

export default Toggle
