import Nav from '../../UI/nav/Nav'
import Footer from '../../UI/footer/Footer'
import gdpr from '../../../images/gdpr.png'
import './gdpr.css'
import { useContext } from 'react'
import { ThemeContext } from '../../../context'

const Gdpr = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  return (
    <div>
      <Nav />
      <section
        className='text-center gdpr'
        style={{
          color: darkMode && '#fff',
          backgroundColor: darkMode && '#222',
        }}>
        <div className='header-bg' />
        <h1
          style={{
            color: darkMode && '#fff',
          }}>
          Adatkezelési tájékoztató
        </h1>
        <br />
        <figure>
          <img src={gdpr} alt='gdpr logo' />
        </figure>
        <h2>1. Az adatkezelési tájékoztató célja</h2>
        <p>
          A Fenster-Bau Kft. (székhely: 1064 Budapest, Podmaniczky utca 57. 2.
          em. 14. a továbbiakban szolgáltató) mint adatkezelő, magára nézve
          kötelezőnek ismeri el jelen jogi közlemény tartalmát.
        </p>
        <p>
          Kötelezettséget vállal arra, hogy tevékenységével kapcsolatos minden
          adatkezelés megfelel a jelen szabályzatban és a hatályos nemzeti
          jogszabályokban, valamint az Európai Unió jogi aktusaiban
          meghatározott elvárásoknak.
        </p>
        <p>
          Jelen adatkezelési tájékoztató az alábbi domain-re és annak
          aldomain-jeire terjed ki:
          <b> ablakesajto.hu</b>
        </p>
        <p>
          Adatkezelő adatkezeléseivel kapcsolatosan felmerülő adatvédelmi
          irányelvek folyamatosan elérhetők a<b> ablakesajto.hu/gdpr</b>
          címen.
        </p>
        <p>
          Adatkezelő fenntartja magának a jogot jelen tájékoztató bármikori
          módosítására. A változásokról az érintettek kellő időben
          tájékoztatásra kerülnek.
        </p>
        <p>
          Amennyiben kérdése lenne jelen közleményhez kapcsolódóan, kérjük, írja
          meg és megválaszoljuk kérdését.
        </p>
        <p>
          Adatkezelő elkötelezett ügyfelei és partnerei személyes adatainak
          védelmében, kiemelten fontosnak tartja ügyfelei információs
          önrendelkezési jogának tiszteletben tartását. Adatkezelő a személyes
          adatokat bizalmasan kezeli, és megtesz minden olyan biztonsági,
          technikai és szervezési intézkedést, mely az adatok biztonságát
          garantálja.
        </p>
        <p>Adatkezelő az alábbiakban ismerteti adatkezelési gyakorlatát.</p>
        <h2>2. Az adatkezelő adatai</h2>
        <p>
          Amennyiben megkeresné a vállalkozást, a
          <a href='mailto:baufenster.kft@gmail.com'>baufenster.kft@gmail.com</a>
          email címen és a <a href='tel:+36301250100'>+36(30)125-0100</a>
          telefonszám elérhetőségeken léphet kapcsolatba az adatkezelővel.
        </p>
        <p>
          <b>Név</b>
          : FENSTER-BAU Kft.
          <br />
          <b>Székhely</b>
          : 1064 Budapest, Podmaniczky utca 57. 2. em. 14.
          <br />
          <b>Adószám</b>
          : 26536527-2-42
          <br />
          <b>Telefonszám</b>: <a href='tel:+36303267891'>+36(30)326 7891</a>
          <br />
          <b>E-mail</b>:
          <a href='mailto:baufenster.kft@gmail.com'>baufenster.kft@gmail.com</a>
        </p>
        <h2>2.1 Adatvédelmi tisztviselő</h2>
        <p>
          Az adatkezelő nem végez olyan tevékenységet, mely indokolttá tenné
          adatvédelmi tisztviselő kijelölését.
        </p>
        <h2>3. A kezelt személyes adatok köre</h2>
        <h2>3.1. Technikai adatok</h2>
        <p>
          Adatkezelő a személyes adatok kezeléséhez a szolgáltatás nyújtása
          során alkalmazott informatikai eszközöket úgy választja meg és
          üzemelteti, hogy a kezelt adat:
        </p>
        <ul className='list'>
          <li>
            - az arra feljogosítottak számára hozzáférhető (rendelkezésre
            állás);
          </li>
          <li>
            - hitelessége és hitelesítése biztosított (adatkezelés hitelessége);
          </li>
          <li>- változatlansága igazolható (adatintegritás);</li>
          <li>
            - a jogosulatlan hozzáférés ellen védett (adat bizalmassága) legyen.
          </li>
        </ul>
        <p>
          Adatkezelő az adatokat megfelelő intézkedésekkel védi a jogosulatlan
          hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés
          vagy megsemmisítés, valamint a véletlen megsemmisülés ellen.
        </p>
        <p>
          Adatkezelő olyan műszaki, szervezési és szervezeti intézkedésekkel
          gondoskodik az adatkezelés biztonságának védelméről, amely az
          adatkezeléssel kapcsolatban jelentkező kockázatoknak megfelelő védelmi
          szintet nyújt.
        </p>
        <p>
          Adatkezelő az adatkezelés során megőrzi a titkosságot: megvédi az
          információt, hogy csak az férhessen hozzá, aki erre jogosult; a
          sértetlenséget: megvédi az információnak és a feldolgozás módszerének
          a pontosságát és teljességét; a rendelkezésre állást: gondoskodik
          arról, hogy amikor a jogosult használónak szüksége van rá, valóban
          hozzá tudjon férni a kívánt információhoz, és rendelkezésre álljanak
          az ezzel kapcsolatos eszközök.
        </p>
        <h2>3.2 Cookie-k (Sütik)</h2>
        <h3>3.2.1 A sütik feladata</h3>
        <p>
          A sütik információkat gyűjtenek a látogatókról és eszközeikről;
          megjegyzik a látogatók egyéni beállításait, amelyek felhasználásra
          kerül(het)nek pl. online tranzakciók igénybevételekor, így nem kell
          újra begépelni őket; megkönnyítik a weboldal használatát; minőségi
          felhasználói élményt biztosítanak, továbbá részt vesznek néhány
          látogatói statisztikai információ gyűjtésében.
        </p>
        <p>
          A testre szabott kiszolgálás érdekében a felhasználó számítógépén kis
          adatcsomagot, ún. sütit (cookie) helyez el és a későbbi látogatás
          során olvas vissza. Ha a böngésző visszaküld egy korábban elmentett
          sütit, a sütit kezelő szolgáltatónak lehetősége van összekapcsolni a
          felhasználó aktuális látogatását a korábbiakkal, de kizárólag a saját
          tartalma tekintetében.
        </p>
        <p>
          A sütik egy része nem tartalmaz az egyéni felhasználó azonosításra
          alkalmas, személyes információkat, egy részük tartalmaz egy titkos,
          véletlenszerűen generált számsort, melyet a felhasználó eszköze
          eltárol és a felhasználó azonosíthatóságát biztosítja.
        </p>
        <h3>3.2.2 Feltétlenül szükséges, munkamenet (session) cookiek</h3>
        <p>
          Ezen sütik célja, hogy a látogatók maradéktalanul és zökkenőmentesen
          böngészhessék a webfolio.hu és a fotofolio.hu weboldalát,
          használhassák annak funkcióit, és az ott elérhető szolgáltatásokat. Az
          ilyen típusú sütik érvényességi ideje a munkamenet (böngészés)
          befejezéséig tart, a böngésző bezárásával a sütik e fajtája
          automatikusan törlődik a számítógépről, illetve a böngészésre használt
          más eszközről.
        </p>
        <h3>3.2.3. Harmadik fél által elhelyezett cookie-k (analitika)</h3>
        <p>
          A webfolio.hu és a fotofolio.hu és aldomain-jeik weboldalain
          alkalmazza a Google Analytics mint harmadik fél sütijeit is. A Google
          Analytics statisztikai célú szolgáltatás használatával az webfolio.hu
          és a fotofolio.hu és aldomain-jeik weboldalai információkat gyűjtenek
          azzal kapcsolatban, hogy a látogatók hogyan használják a weboldalakat.
          Az adatot a honlap fejlesztésének és a felhasználói élmény javításának
          céljával használja fel. Ezen sütik szintén lejáratukig a látogató
          számítógépén vagy böngészésre használt más eszközén, annak
          böngészőjében maradnak, illetve amíg a látogató nem törli őket.
        </p>
        <h3>3.2.4. A cookie kezelés jogalapja</h3>
        <p>
          A cookie kezelés jogalapja a weboldal látogatójának hozzájárulása, a
          vonatkozó Rendelet 6. cikk (1) bekezdés a pontja alapján.
        </p>
        <p>
          Amennyiben nem fogadja el a cookie-k használatát, akkor a 3.2.3.
          pontban felsorolt weboldalak bizonyos funkciói nem lesznek elérhetőek
          a weboldalak használata során, illetve előfordulhat, hogy bizonyos
          funkciók hibásan működnek.
        </p>
        <p>
          A cookie-k törléséről bővebb információt a gyakoribb böngészők
          tekintetében az alábbi linkeken találhat:
        </p>
        <ul>
          <li>
            Firefox:
            <a
              href='https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito'
              target='_blank'
              rel='noopener noreferrer'>
              Weboldalak által elhelyezett sütik törlése a számítógépről
            </a>
          </li>
          <li>
            Chrome:
            <a
              href='https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&amp;hl=en'
              target='_blank'
              rel='noopener noreferrer'>
              Clear cache &amp; cookies
            </a>
          </li>
          <li>
            Safari:
            <a
              href='https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac'
              target='_blank'
              rel='noopener noreferrer'>
              Manage cookies and website data in Safari on Mac
            </a>
          </li>
        </ul>
        <h3>3.2.5. Az adatkezelő weboldalain szereplő cookiek listája</h3>

        <div className='table'>
          <ul>
            <li className='table-header'>
              <p>Név</p>
              <p>Szolgáltatások</p>
              <p>Leírás</p>
              <p>Elévülés</p>
              <p>Jelleg</p>
            </li>
            <li>
              <p>A weboldalak működéséhez szükséges sütik</p>
              <p />
              <p>
                A sütik a weboldal használatához nélkülözhetetlenek, és lehetővé
                teszik a weboldal alapvető funkcióinak használatát. Ezek
                hiányába az oldal számos funkciója nem lesz elérhető.
              </p>
              <p>Munkamenet vége</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>A felhasználói élményt javító sütik</p>
              <p />
              <p>
                A sütik a weboldal használatáról gyűjtenek információkat és a
                felhasználói élményt javítását szolgálják.
              </p>
              <p>Munkamenet vége</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>Munkamenet sütik</p>
              <p />
              <p>
                A sütik a látogató tartózkodási helyét, a böngésző nyelvét, a
                fizetés pénznemét tárolják.
              </p>
              <p>Maximun 2 óra vagy a böngésző bezárása.</p>
              <p />
            </li>
            <li>
              <p>1P_JAR</p>
              <p>Google.com és Gstatic.com</p>
              <p>
                A cookie-t webhelystatisztikák összegyűjtésére és a konverziós
                arány követésére használják.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>CONSENT</p>
              <p>retsagautoszerviz.hu</p>
              <p>
                A cookie a cookie-k elfogadásával összefüggő beállítások
                tárolására használják
              </p>
              <p>1 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>_ga</p>
              <p>Google.com</p>
              <p>
                A Google Analytics cookie-kat a webhelyünkön lévő forgalom
                mérésére használjuk. Egyetlen szöveges sáv kerül mentésre a
                böngésző, a kölcsönhatások időbélyegzőjének és a böngésző /
                forrásoldal azonosításához, amely a felhasználót a
                weboldalunkhoz vezette.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>_gat</p>
              <p>Google.com</p>
              <p>
                A Google Analytics cookie-kat a webhelyünkön lévő forgalom
                mérésére használjuk. Egyetlen szöveges sáv kerül mentésre a
                böngésző, a kölcsönhatások időbélyegzőjének és a böngésző /
                forrásoldal azonosításához, amely a felhasználót a
                weboldalunkhoz vezette.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>_gid</p>
              <p>Google.com</p>
              <p>
                A Google Analytics cookie-kat a webhelyünkön lévő forgalom
                mérésére használjuk. Egyetlen szöveges sáv kerül mentésre a
                böngésző, a kölcsönhatások időbélyegzőjének és a böngésző /
                forrásoldal azonosításához, amely a felhasználót a
                weboldalunkhoz vezette.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>APISID, sapisid, ssid, NID, sid, HSID</p>
              <p>Google.com és Gstatic.com</p>
              <p>
                Biztonsági cookie-k a felhasználók hitelesítése, a
                bejelentkezési adatokkal való visszaélés megelőzése, továbbá
                annak érdekében, hogy megvédjük a felhasználói adatokat az
                illetéktelen személyektől. A SID és a HSID nevű cookie-kat
                használjuk a felhasználó Google Fiókjához tartozó azonosító
                digitálisan aláírt és titkosított adatainak és a legutóbbi
                bejelentkezési időpont tárolására. Ennek a két cookie-nak a
                segítségével számos támadást meg tudunk akadályozni, például a
                weboldalakon kitöltött űrlapok tartalmának ellopására irányuló
                kísérleteket is.
              </p>
              <p>10 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>OTZ</p>
              <p>Google.com és Gstatic.com</p>
              <p>
                Az “OTZ” a Google Analytics által használt cookie, amely a
                webhely látogatóinak összesített elemzését nyújtja.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>NID</p>
              <p>Google.com és Gstatic.com</p>
              <p>
                Ezek a cookie-k lehetővé teszik, hogy webhelyeink megjegyezzék a
                webhely működési vagy megjelenítési módját, például az Ön által
                beállított nyelvet vagy az Ön tartózkodási régióját módosító
                információkat. A régió megjegyzése révén például a webhely helyi
                időjárás-jelentést vagy közlekedési híreket tud szolgáltatni. E
                cookie-k segítségével a szöveg méretét, a betűtípust, valamint a
                weboldalak egyéb részeit is személyre lehet szabni.
              </p>
              <p>10 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>SIDCC</p>
              <p>Google.com és Gstatic.com</p>
              <p>
                egy biztonsági cookie, amely védi a felhasználó adatait a
                jogosulatlan hozzáféréstől.
              </p>
              <p>2 év</p>
              <p>nem gyűjt személyes információkat</p>
            </li>
            <li>
              <p>c_user</p>
              <p>facebook.com</p>
              <p>
                Az xs cookie-val együttesen az identitásodat hitelesíti a
                Facebook felé.
              </p>
              <p>6 hónap</p>
              <p>
                Felhasználói azonosító a Facebook felé, a facebook.com
                adatvédelmi nyilatkozata szerint.
              </p>
            </li>
            <li>
              <p>datr</p>
              <p>facebook.com</p>
              <p>
                A böngészőket azonosítja biztonság és az oldal integritása
                érdekében, többek között fiók-helyreállításra, továbbá a
                potenciálisan sérült biztonságú fiókok azonosítására.
              </p>
              <p>2 év</p>
              <p>
                Böngészőazonosító és időbélyeg a facebook.com adatvédelmi
                nyilatkozata szerint.
              </p>
            </li>
            <li>
              <p>fr</p>
              <p>facebook.com</p>
              <p>
                A Facebook elsődleges hirdetési cookie-ja, használatával
                megjeleníthetők, mérhetők a hirdetések, illetve javítható a
                relevanciájuk.
              </p>
              <p>3 hónap</p>
              <p>
                Felhasználó- és böngészőazonosító, időbélyeg, egyéb adatok a
                facebook.com adatvédelmi nyilatkozata szerint
              </p>
            </li>
            <li>
              <p>sb</p>
              <p>facebook.com</p>
              <p>A böngészőt azonosítja bejelentkezési hitelesítési célból.</p>
              <p>2 év</p>
              <p>
                Felhasználó- és böngészőazonosító, időbélyeg, egyéb adatok a
                facebook.com adatvédelmi nyilatkozata szerint.
              </p>
            </li>
            <li>
              <p>wd</p>
              <p>facebook.com</p>
              <p>Anonim statisztika.</p>
              <p>1 hét</p>
              <p>
                Felhasználó- és böngészőazonosító, időbélyeg, egyéb adatok a
                facebook.com adatvédelmi nyilatkozata szerint.
              </p>
            </li>
            <li>
              <p>xs</p>
              <p>facebook.com</p>
              <p>
                A c_user cookie-val együttesen az identitásodat hitelesíti a
                Facebook felé.
              </p>
              <p>6 hónap</p>
              <p>
                Munkamenet-azonosító, létrehozás időpontja, hitelesítési érték,
                biztonságos munkamenet állapota, gyorsítótárazási
                csoportazonosító, a facebook.com adatvédelmi nyilatkozata
                szerint.
              </p>
            </li>
          </ul>
        </div>

        <h2>
          4. Általános adatkezelési irányelvek, adatkezelés neve, felhasználása,
          jogalap és megőrzési idő
        </h2>
        <p>
          Adatkezelő tevékenységének adatkezelései önkéntes hozzájáruláson,
          illetve törvényi felhatalmazáson alapulnak. Az önkéntes hozzájáruláson
          alapuló adatkezelések esetében az érintettek e hozzájárulásukat az
          adatkezelés bármely szakában visszavonhatják.
        </p>
        <p>
          Bizonyos esetekben a megadott adatok egy körének kezelését, tárolását,
          továbbítását jogszabályok teszik kötelezővé, melyről külön értesítjük
          ügyfeleinket. Felhívjuk Adatkezelő részére adatközlők figyelmét, hogy
          amennyiben nem saját személyes adataikat adják meg, az adatközlő
          kötelessége az érintett hozzájárulásának beszerzése. Adatkezelési
          alapelvei összhangban vannak az adatvédelemmel kapcsolatos hatályos
          jogszabályokkal, így különösen az alábbiakkal: 2011. évi CXII. törvény
          &#8211; az információs önrendelkezési jogról és az
          információ-szabadságról (Infotv.);
        </p>
        <p>
          Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016.
          április 27.) – a természetes személyeknek a személyes adatok kezelése
          tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
          valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános
          adatvédelmi rendelet, GDPR); 2013. évi V. törvény – a Polgári
          Törvénykönyvről (Ptk.); 2000. évi C. törvény – a számvitelről (Számv.
          tv.); 2017. évi LIII. törvény – a pénzmosás és terrorizmus
          finanszírozása megelőzéséről és megakadályozásáról (Pmt.); 2013. évi
          CCXXXVII. törvény – a hitelintézetekről és a pénzügyi vállalkozásokról
          (Hpt.).
        </p>

        <h2>4.1 Online ügyintézéshez kapcsolódó adatok</h2>
        <p>
          <b>A kapcsolatfelvétel során elkért személyes adatok:</b>
        </p>
        <p>
          <b>Név</b>
          (kötelező mező)
        </p>
        <p>
          <b>Email cím</b>
          (kötelező mező)
        </p>
        <p>
          <b>Telefonszám</b>
          (kötelező mező, visszahívás kezdeményezéséhez)
          <br />
          <b>Az adatkezelés célja, a kezelt adatok tervezett felhasználása:</b>
          Az adatok kapcsolatfelvétel és a megrendelés teljesítésére kerülnek
          felhasználásra.
        </p>
        <p>
          Az adatkezelés jogalapja önkéntes hozzájárulás.
          <br />
          Megőrzési idő: üzleti kapcsolat időtartama, vagy törlési kérelem.
        </p>
        <h2>5. Az adatok fizikai tárolási helyei</h2>
        <p>
          Személyes adatai (vagyis azok az adatok, amelyek az Ön személyével
          kapcsolatba hozhatók) a következő módon kerülhetnek a kezelésünkbe:
        </p>
        <ul>
          <li>
            egyfelől az internetes kapcsolat fenntartásával összefüggésben az Ön
            által használt számítógéppel, böngészőprogrammal, internetes címmel,
            a látogatott oldalakkal kapcsolatos technikai adatok automatikusan
            képződnek számítógépes rendszerünkben, másfelől Ön is megadhatja
            nevét, elérhetőségét vagy más adatait, ha a honlap használata során
            személyes kapcsolatba kíván lépni velünk. A rendszer működtetése
            során technikailag rögzítésre kerülő adatok: az érintett
            bejelentkező számítógépének azon adatai, melyeket a ablakesajto.hu
            domain rendszere a technikai folyamatok automatikus eredményeként
            rögzít.
          </li>
        </ul>
        <p>
          Az automatikusan rögzítésre kerülő adatot a rendszer az érintett külön
          nyilatkozata vagy cselekménye nélkül a belépéskor, illetve kilépéskor
          automatikusan naplózza.
        </p>
        <p>
          Ezen adat egyéb személyes felhasználói adatokkal – törvény által
          kötelezővé tett esetek kivételével – össze nem kapcsolható. Az adathoz
          kizárólag az <span>ablakesajto.hu</span> domain és aldomainjei férnek
          hozzá.
        </p>
        <h2>6. Adattovábbítás, adatfeldogozás, az adatokat megismerők köre</h2>
        <p>
          Adatkezelő vállalkozási tevékenysége keretében az alábbi
          adatfeldolgozókat veszi igénybe:
        </p>
        <p>
          <strong>Tárhelyszolgáltatás:</strong>
          <br />
          Netlify Inc.,
          <br />
          610 22nd St #315, San Francisco
          <br />
          Ügyfélszolgálat:
          <a href='mailto:support@netlify.com'>support@netlify.com</a>
          <br />
          E-mail:
          <a href='mailto:support@netlify.com'>support@netlify.com</a>
          <br />
          Megismert adatok köre: ablakesajto.hu domain és aldomaineken található
          weboldalak tartalma, ezen domain-ekre alapuló email címekre érkező
          emailek.
        </p>
        <br />
        <p>
          <strong>Google Analytics:</strong>
          Google Inc., Mountain View, California, USA
          <br />
          Megismert adatok köre: az <b>ablakesajto.hu </b>
          weboldal látogatóinak – anonimizált, személyhez nem köthető – IP címe.
        </p>
        <p>
          <strong>Facebook oldal:</strong>
          <br />
          Facebook Inc.
          <br />
          Menlo Park, California, USA
          <br />
          Adatkezelési tájékoztató:
          <a
            href='https://www.facebook.com/about/privacy/update'
            target='_blank'
            rel='noopener noreferrer'>
            facebook privacy
          </a>
          <br />
          Megismert adatok köre: felhasználónév, hozzászólás.
        </p>
        <h2>6.1 Adattovábbítás harmadik országba</h2>
        <p>
          Egyedüli EU-n kívüli (harmadik) ország, amelybe adattovábbítás
          történik, az Amerikai Egyesült Államok, mellyel megfelelőségi
          határozat született 2016. július 12-én (
          <a href='https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en'>
            EU-US Privacy Shield
          </a>
          ). A megfelelőségi határozat a
          <a href='https://policies.google.com/privacy/frameworks'>Google</a>
          és a
          <a href='https://www.facebook.com/about/privacyshield'>Facebook</a>
          adatkezelők esetében is érvényes.
        </p>
        <h2>7. Érintett jogai és jogérvényesítési lehetőségei</h2>
        <p>
          Az érintett tájékoztatást kérhet személyes adatai kezeléséről,
          valamint kérheti személyes adatainak helyesbítését, illetve – a
          kötelező adatkezelések kivételével – törlését, visszavonását, élhet
          adathordozási-, és tiltakozási jogával az adat felvételénél jelzett
          módon, illetve az adatkezelő fenti elérhetőségein.
        </p>
        <h2>7.1 Tájékoztatáshoz való jog</h2>
        <p>
          Adatkezelő megfelelő intézkedéseket hoz annak érdekében, hogy az
          érintettek részére a személyes adatok kezelésére vonatkozó, a GDPR 13.
          és a 14. cikkben említett valamennyi információt és a 15–22. és 34.
          cikk szerinti minden egyes tájékoztatást tömör, átlátható, érthető és
          könnyen hozzáférhető formában, világosan és közérthetően megfogalmazva
          nyújtsa.
        </p>
        <h2>7.2 Az érintett hozzáféréshez való joga</h2>
        <p>
          Az érintett jogosult arra, hogy az adatkezelőtől visszajelzést kapjon
          arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e,
          és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a
          személyes adatokhoz és a következő információkhoz hozzáférést kapjon:
        </p>
        <ul>
          <li>az adatkezelés céljai;</li>
          <li>az érintett személyes adatok kategóriái;</li>
          <li>
            azon címzettek vagy címzettek kategóriái, akikkel, illetve
            amelyekkel a személyes adatokat közölték vagy közölni fogják,
            ideértve különösen a harmadik országbeli címzetteket, illetve a
            nemzetközi szervezeteket;
          </li>
          <li>a személyes adatok tárolásának tervezett időtartama;</li>
          <li>
            a helyesbítés, törlés vagy adatkezelés korlátozásának és a
            tiltakozás joga;
          </li>
          <li>a felügyeleti hatósághoz címzett panasz benyújtásának joga;</li>
          <li>az adatforrásokra vonatkozó információ;</li>
          <li>
            az automatizált döntéshozatal ténye, ideértve a profilalkotást is,
            valamint az alkalmazott logikára és arra vonatkozó érthető
            információk, hogy az ilyen adatkezelés milyen jelentőséggel bír, és
            az érintettre nézve milyen várható következményekkel jár.
          </li>
        </ul>
        <p>
          Az adatkezelő a kérelem benyújtásától számított legfeljebb egy hónapon
          belül adja meg a tájékoztatást.
        </p>
        <h2>7.3 Helyesbítés joga</h2>
        <p>
          Az érintett kérheti Adatkezelő által kezelt, rá vonatkozó pontatlan
          személyes adatok helyesbítését és a hiányos adatok kiegészítését.
        </p>
        <h2>7.4 Törléshez való jog</h2>
        <p>
          Az érintett az alábbi indokok valamelyikének fennállása esetén
          jogosult arra, hogy kérésére Adatkezelő indokolatlan késedelem nélkül
          törölje a rá vonatkozó személyes adatokat: személyes adatokra már
          nincs szükség abból a célból, amelyből azokat gyűjtötték vagy más
          módon kezelték; az érintett visszavonja az adatkezelés alapját képező
          hozzájárulását, és az adatkezelésnek nincs más jogalapja; az érintett
          tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű
          ok az adatkezelésre; a személyes adatokat jogellenesen kezelték; a
          személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami
          jogban előírt jogi kötelezettség teljesítéséhez törölni kell; a
          személyes adatok gyűjtésére információs társadalommal összefüggő
          szolgáltatások kínálásával kapcsolatosan került sor.
        </p>
        <p>
          Az adatok törlése nem kezdeményezhető, ha az adatkezelés szükséges: a
          véleménynyilvánítás szabadságához és a tájékozódáshoz való jog
          gyakorlása céljából; a személyes adatok kezelését előíró, az
          adatkezelőre alkalmazandó uniós vagy tagállami jog szerinti
          kötelezettség teljesítése, illetve közérdekből vagy az adatkezelőre
          ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat
          végrehajtása céljából; a népegészség-ügy területét érintő, vagy
          archiválási, tudományos és történelmi kutatási célból vagy
          statisztikai célból, közérdek alapján; vagy jogi igények
          előterjesztéséhez, érvényesítéséhez, illetve védelméhez.
        </p>
        <h2>7.5 Az adatkezelés korlátozásához való jog</h2>
        <p>
          Az érintett kérésére Adatkezelő korlátozza az adatkezelést, ha az
          alábbi feltételek valamelyike teljesül: az érintett vitatja a
          személyes adatok pontosságát, ez esetben a korlátozás arra az
          időtartamra vonatkozik, amely lehetővé teszi, a személyes adatok
          pontosságának ellenőrzését; az adatkezelés jogellenes, és az érintett
          ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának
          korlátozását; az adatkezelőnek már nincs szüksége a személyes adatokra
          adatkezelés céljából, de az érintett igényli azokat jogi igények
          előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy az érintett
          tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az
          időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az
          adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos
          indokaival szemben.
        </p>
        <p>
          Ha az adatkezelés korlátozás alá esik, a személyes adatokat a tárolás
          kivételével csak az érintett hozzájárulásával, vagy jogi igények
          előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más
          természetes vagy jogi személy jogainak védelme érdekében, vagy az
          Unió, illetve valamely tagállam fontos közérdekéből lehet kezelni.
        </p>
        <h2>7.6 Adathordozáshoz való jog</h2>
        <p>
          Az érintett jogosult arra, hogy a rá vonatkozó, általa az adatkezelő
          rendelkezésére bocsátott személyes adatokat tagolt, széles körben
          használt, géppel olvasható formátumban megkapja, és ezeket az adatokat
          egy másik adatkezelőnek továbbítsa.
        </p>
        <h2>7.7 Tiltakozás joga</h2>
        <p>
          Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos
          okokból bármikor tiltakozzon személyes adatainak közérdekű vagy az
          adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében
          végzett feladat végrehajtásához szükséges adatkezelés, vagy az
          adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez
          szükséges kezelése ellen, ideértve az említett rendelkezéseken alapuló
          profilalkotást is. Tiltakozás esetén az adatkezelő a személyes
          adatokat nem kezelheti tovább, kivéve, ha azt olyan kényszerítő erejű
          jogos okok indokolják, amelyek elsőbbséget élveznek az érintett
          érdekeivel, jogaival és szabadságaival szemben, vagy amelyek jogi
          igények előterjesztéséhez, érvényesítéséhez vagy védelméhez
          kapcsolódnak.
        </p>
        <h2>
          7.8 Automatizált döntéshozatal egyedi ügyekben, beleértve a
          profilalkotást
        </h2>
        <p>
          Az érintett jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag
          automatizált adatkezelésen – ideértve a profilalkotást is – alapuló
          döntés hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen
          jelentős mértékben érintené.
        </p>
        <p>
          <b>
            Automatizált döntéshozatalt és / vagy profilalkotást vállalkozásunk
            nem végez.
          </b>
        </p>
        <h2>7.9 Visszavonás joga</h2>
        <p>
          Az érintett jogosult arra, hogy hozzájárulását bármikor visszavonja.
        </p>
        <h2>7.10 Bírósághoz fordulás joga</h2>
        <p>
          Az érintett a jogainak megsértése esetén az adatkezelő ellen
          bírósághoz fordulhat. A bíróság az ügyben soron kívül jár el. 8.11
          Adatvédelmi hatósági eljárás Panasszal a Nemzeti Adatvédelmi és
          Információszabadság Hatóságnál lehet élni:
          <br />
          Név: Nemzeti Adatvédelmi és Információszabadság Hatóság Székhely: 1125
          Budapest, Szilágyi Erzsébet fasor 22/C. Levelezési cím: 1530 Budapest,
          Pf.: 5. Telefon: 0613911400 Fax: 0613911410
        </p>
        <p>
          E-mail:
          <a href='mailto:ugyfelszolgalat@naih.hu'>ugyfelszolgalat@naih.hu</a>
          <br />
          Honlap:
          <a href='http://www.naih.hu' target='_blank' rel='noreferrer'>
            http://www.naih.hu
          </a>
        </p>
        <h2>8. Egyéb rendelkezések</h2>
        <p>
          E tájékoztatóban fel nem sorolt adatkezelésekről az adat felvételekor
          adunk tájékoztatást. Tájékoztatjuk ügyfeleinket, hogy a bíróság, az
          ügyész, a nyomozó hatóság, a szabálysértési hatóság, a közigazgatási
          hatóság, a Nemzeti Adatvédelmi és Információszabadság Hatóság, a
          Magyar Nemzeti Bank, illetőleg jogszabály felhatalmazása alapján más
          szervek tájékoztatás adása, adatok közlése, átadása, illetőleg iratok
          rendelkezésre bocsátása végett megkereshetik az adatkezelőt.
          Adatkezelő a hatóságok részére – amennyiben a hatóság a pontos célt és
          az adatok körét megjelölte – személyes adatot csak annyit és olyan
          mértékben ad ki, amely a megkeresés céljának megvalósításához
          elengedhetetlenül szükséges.
        </p>
      </section>
      <Footer />
    </div>
  )
}

export default Gdpr
