import Nav from '../../UI/nav/Nav'
import Footer from '../../UI/footer/Footer'
import './impressum.css'
import netlify from '../../../images/netlify.png'
import { useContext } from 'react'
import { ThemeContext } from '../../../context'

function Impressum() {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  return (
    <div>
      <Nav />
      <section
        className='impressum'
        style={{
          color: darkMode && '#fff',
          backgroundColor: darkMode && '#222',
        }}>
        <div className='header-bg' />
        <h1>Impresszum</h1>
        <ul>
          <li className=' bg-gearbox '>Tárhelyszolgáltató</li>
          <li>
            <b>Cégnév:Netlify Inc.</b>
            <img src={netlify} style={{ width: '50px' }} alt='Netlify logo' />
          </li>

          <li>
            <b>Székhely:</b>
            610 22nd St #315, San Francisco
          </li>
          <li>
            <b>Levelezési cím:</b>
            610 22nd St #315, San Francisco
          </li>
          <li>
            <b>Email:</b>
            <a href='mailto:support@netlify.com'>support@netlify.com</a>
          </li>
          <li className='bg-gearbox'>Weboldal Üzemeltetője</li>
          <li>
            <b>FENSTER-BAU Kft.</b>
          </li>
          <li>
            <b>Székhely / levelezési cím:</b>
            1064 Budapest, Podmaniczky utca 57. 2. em. 14.
          </li>
          <li>
            <b>Nyilvántartásba bejegyző hatóság:</b>
            Belügyminisztérium Nyilvántartások Vezetéséért Felelős Helyettes
            Államtitkár Okmányfelügyeleti Főosztály
          </li>
          <li>
            <b>Adószám:</b>
            26536527-2-42
          </li>
          <li>
            <b>Telefonszám:</b>
            <a href='tel:+36303267891'>+36(30)326-7891</a>
          </li>
          <li>
            <b>E-mail cím:</b>
            <a href='mailto:fensterbau.kft@gmail.com'>
              baufenster.kft@gmail.com
            </a>
          </li>
          <li>
            <b>Iparkamara:</b>
            Pest Megyei Kereskedelmi és Iparkamara
          </li>
        </ul>
      </section>
      <Footer />
    </div>
  )
}

export default Impressum
