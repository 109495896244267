import Product from '../product/Product'
import './productList.css'
import { products } from '../../../data'
import marshall from '../../../images/marshall-logo.svg'

const ProductList = () => {
  return (
    <div className='pl' id='products'>
      <div className='pl-texts'>
        <h1 className='pl-title'>Forgalmazott termékek</h1>
        <p className='pl-desc'>
          A Fenster-Bau Kft. évtizedes tapasztalattal rendelkezik mind az
          ár/érték arányban legmegfelelőbb termékek kiválasztásában, mind pedig
          ezek igényes, szakszerű beszerelésében. Külföldi és hazai
          referenciáink bizonytják szaktudásunkat és elkötelezettségünket.
        </p>
      </div>
      <div className='pl-list'>
        {products.map((item) => (
          <Product
            key={item.id}
            img={item.img}
            alt={item.alt}
            title={item.title}
            desc={item.description}
          />
        ))}
      </div>
      <div className='m'>
        <div className='m-title'>
          <h2>
            Cégünk a MARSHALL, Közép-Európa egyik legnagyobb kapacitású, és
            egyben legmodernebb ajtó-ablak gyártójának hivatalos viszonteladója!
          </h2>
        </div>
        <div className='m-img'>
          <img loading='lazy' src={marshall} alt='Marshall logo' />
        </div>
      </div>
    </div>
  )
}

export default ProductList
