import './product.css'

const Product = ({ img, alt, title, desc }) => {
  return (
    <div className='card-wrapper'>
      <div className='title-wrapper'>
        <h2>{title}</h2>
      </div>
      <div className='p'>
        <div className='p-browser'>
          <div className='p-circle c1'></div>
          <div className='p-circle c2'></div>
          <div className='p-circle c3'></div>
        </div>
        <img loading='lazy' src={img} alt={alt} className='p-img' />
        <div className='p-wrapper'>
          <p className='p-text'>{desc}</p>
        </div>
      </div>
    </div>
  )
}

export default Product
