import { React, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../../context'
import Toggle from '../toggle/Toggle'
import Logo from '../../../images/fenster-logo.svg'
import './nav.css'

const Nav = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  const isMobile = window.innerWidth < 1024
  const [clicked, setClicked] = useState(false)
  return (
    <>
      {!isMobile ? (
        <nav className='n' style={{ background: darkMode && '#222' }}>
          <Toggle />
          <ul className='logo'>
            <li>
              <Link to='/#home' aria-label='home icon'>
                <img src={Logo} alt='Fenster-Bau logo' className='logo-img' />
              </Link>
            </li>
          </ul>
          <ul className='menu'>
            <li>
              <a href='/#about' style={{ color: darkMode && '#fefefe' }}>
                Rólunk
              </a>
            </li>
            <li>
              <a href='/#products' style={{ color: darkMode && '#fefefe' }}>
                Termékeink
              </a>
            </li>
            <li>
              <a href='/#gallery' style={{ color: darkMode && '#fefefe' }}>
                Galéria
              </a>
            </li>
            <li>
              <a href='/#contact' style={{ color: darkMode && '#fefefe' }}>
                Kapcsolat
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <nav style={{ color: darkMode && '#fefefe' }}>
          <div className='header'>
            <ul className='logo'>
              <li>
                <Link to='/#home' aria-label='home icon'>
                  <img src={Logo} alt='Fenster-Bau logo' className='logo-img' />
                </Link>
              </li>
            </ul>
            <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
              <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
              <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
              <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
            </div>
          </div>
          <ul
            style={{ color: darkMode && '#fefefe' }}
            className={`mobile-menu ${clicked ? 'mobile-active' : ''}`}>
            <Toggle />
            <li>
              <a
                href='#about'
                style={{ color: darkMode && '#222' }}
                onClick={() => setClicked(!clicked)}>
                Rólunk
              </a>
            </li>
            <li>
              <a
                href='#products'
                style={{ color: darkMode && '#222' }}
                onClick={() => setClicked(!clicked)}>
                Termékeink
              </a>
            </li>
            <li>
              <a
                href='#gallery'
                style={{ color: darkMode && '#222' }}
                onClick={() => setClicked(!clicked)}>
                Galéria
              </a>
            </li>
            <li>
              <a
                href='#contact'
                style={{ color: darkMode && '#222' }}
                onClick={() => setClicked(!clicked)}>
                Kapcsolat
              </a>
            </li>
          </ul>
        </nav>
      )}
    </>
  )
}

export default Nav
