import './intro.css'
import shocked from '../../../images/shocked.webp'
import { useContext } from 'react'
import { ThemeContext } from '../../../context'

const Intro = () => {
  const theme = useContext(ThemeContext)
  const darkMode = theme.state.darkMode
  return (
    <div className='i' id='home'>
      <div className='i-left'>
        <div className='i-left-wrapper'>
          <h2 className='i-intro'>Fenster-Bau Kft.</h2>
          <h1 className='i-name'>Nyílászáró Szaküzlet</h1>
          <div className='i-title'>
            <div
              className='i-title-wrapper'
              style={{ color: darkMode && '#91a3c2' }}>
              <div className='i-title-item'>Ablak-Ajtó</div>
              <div className='i-title-item'>Redőny</div>
              <div className='i-title-item'>Zsaluzia</div>
              <div className='i-title-item'>Szúnyogháló-rendszerek</div>
              <div className='i-title-item'>Beltéri ajtók</div>
            </div>
          </div>
          <div className='i-description'>
            <p>
              Nyílászárók értékesítése és kivitelezése több min 10 éves -bel és
              külföldi- tapasztalattal, a legmodernebb technológiával, korszerű
              anyagokkal. Ablak, ajtó, redőny, zsaluzia, szúnyogháló-rendszerek,
              beltéri ajtók.
            </p>
          </div>
          <svg
            viewBox='0 0 79.37 122.88'
            fill='#2a4f8f'
            stroke='#2a4f8f'
            className='i-scroll'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='m50.2 121.63c6.71-1.85 12.72-5.44 17.51-10.23 7.19-7.19 11.65-17.11 11.65-28.03v-43.69c0-10.92-4.46-20.84-11.65-28.03s-17.11-11.65-28.03-11.65c-10.91 0-20.84 4.46-28.03 11.65s-11.65 17.12-11.65 28.03v43.68c0 10.92 4.46 20.84 11.65 28.03 5.59 5.59 12.82 9.53 20.89 11.01 4.88.9 13.16.47 17.66-.77zm-10.97-29.57c4.15 0 7.55-3.4 7.55-7.55v-7.78c0-4.15-3.4-7.55-7.55-7.55s-7.55 3.4-7.55 7.55v7.78c0 4.15 3.39 7.55 7.55 7.55zm21.85 12.71c-5.49 5.49-13.07 8.91-21.4 8.91s-15.9-3.41-21.4-8.91c-5.49-5.49-8.91-13.07-8.91-21.4v-43.69c0-8.33 3.41-15.9 8.91-21.4 5.49-5.49 13.07-8.91 21.4-8.91s15.9 3.41 21.4 8.91c5.49 5.49 8.91 13.07 8.91 21.4v43.68c0 8.34-3.41 15.91-8.91 21.41z' />
          </svg>
        </div>
      </div>
      <div className='i-right'>
        <div className='i-bg'></div>
        <img src={shocked} alt='Fenster-Bau' className='i-img' loading='lazy' />
      </div>
    </div>
  )
}

export default Intro
