import React from 'react'
import { Link } from 'react-router-dom'

import './footer.css'
import logo from '../../../images/fenster-logo.svg'
import faceLogo from '../../../images/face.png'

const Footer = () => {
  return (
    <footer className='f'>
      <div className='f-top'>
        <section className='f-block-left'>
          <div className='footer-logo-img'>
            <img src={logo} alt='fenster-logo' />
          </div>
        </section>
        <section className='f-block-center'>
          <div className='f-list'>
            {/* <h6>Tájékoztatók</h6> */}
            <ul>
              <li>
                <Link to='/cookies'>Sütik</Link>
              </li>
              <li>
                <Link to='/gdpr'>Adatkezelési tájékoztató</Link>
              </li>
              <li>
                <Link to='/impresszum'>Impresszum</Link>
              </li>
            </ul>
          </div>
          <div className='f-social'>
            <a
              className='face-wrapper'
              href='https://www.facebook.com/Ny%C3%ADl%C3%A1sz%C3%A1r%C3%B3-Szak%C3%BCzlet-Balassagyarmat-109542147626324'
              target='_blank'
              rel='noreferrer'>
              <img src={faceLogo} alt='facebook ikon' />
              <p>Nyílászáró Szaküzlet Balassagyarmat</p>
            </a>
            <a
              className='face-wrapper'
              href='https://www.facebook.com/M%C5%B1anyag-Ablak-Ajt%C3%B3-Szombathely-103767752395293'
              target='_blank'
              rel='noreferrer'>
              <img src={faceLogo} alt='facebook ikon' />
              <p>Műanyag Ablak-Ajtó Szombathely</p>
            </a>
          </div>
        </section>
        <section className='f-block-right'>
          <div className='f-menu'>
            <h6>Oldaltérkép</h6>
            <ul>
              <li>
                <a href='/#home'>Főmenü</a>
              </li>
              <li>
                <a href='/#about'>Rólunk</a>
              </li>
              <li>
                <a href='/#products'>Termékek</a>
              </li>
              <li>
                <a href='/#gallery'>Galéria</a>
              </li>
              <li>
                <a href='/#contact'>Kapcsolat</a>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <div className='f-bottom'>
        <div className='f-scroll'>
          <h6 className='f-scroll-item'>
            nyílászáró szaküzlet Balassagyarmat, 2660 Rákóczi út 65/C.
          </h6>
        </div>
      </div>
    </footer>
  )
}

export default Footer
